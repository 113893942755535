var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("sockets.title")))]),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.sockets,"dense":""},on:{"click:row":_vm.displayDetails},scopedSlots:_vm._u([{key:"item.nsp",fn:function(ref){
var value = ref.value;
return [_c('code',[_vm._v(_vm._s(value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isSocketDisconnectSupported)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"disabled":_vm.isReadonly,"small":""},on:{"click":function($event){return _vm.disconnect(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("sockets.disconnect")))])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }