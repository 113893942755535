var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_c('v-card',[_c('v-card-text',[_c('NamespaceSelector')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.events,"footer-props":_vm.footerProps,"item-key":"eventId","sort-by":['timestamp', 'eventId'],"sort-desc":[true, true],"single-expand":"","show-expand":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var value = ref.value;
return [_c('EventType',{attrs:{"type":value}})]}},{key:"item.id",fn:function(ref){
var value = ref.value;
return [_c('router-link',{staticClass:"link",attrs:{"to":_vm.socketDetailsRoute(value)}},[_vm._v(_vm._s(value))])]}},{key:"item.args",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [(_vm.isExpandable(item))?_c('span',[_vm._v(" "+_vm._s(_vm.$t("events.eventName"))+_vm._s(_vm.$t("separator"))),_c('code',[_vm._v(_vm._s(item.eventName))])]):(item.type === 'disconnection')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("events.reason"))+_vm._s(_vm.$t("separator"))),_c('code',[_vm._v(_vm._s(value))])]):(item.type === 'room_joined' || item.type === 'room_left')?_c('span',[_vm._v(" "+_vm._s(_vm.$t("events.room"))+_vm._s(_vm.$t("separator"))),_c('code',[_vm._v(_vm._s(value))])]):_c('span',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(_vm.isExpandable(item) && !isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(_vm.isExpandable(item) && isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"ma-3"},[_vm._v(" "+_vm._s(_vm.$t("events.eventArgs"))+_vm._s(_vm.$t("separator"))+" "),_c('pre',[_c('code',[_vm._v(_vm._s(item.args))])])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }