var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_c('v-card',[_c('v-card-text',{staticClass:"d-flex"},[_c('NamespaceSelector'),_c('v-switch',{staticClass:"ml-3",attrs:{"label":_vm.$t('rooms.show-private'),"inset":"","dense":""},on:{"change":_vm.onPrivateRoomsUpdate},model:{value:(_vm.showPrivateRooms),callback:function ($$v) {_vm.showPrivateRooms=$$v},expression:"showPrivateRooms"}})],1),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.filteredRooms,"footer-props":_vm.footerProps},on:{"click:row":_vm.displayDetails},scopedSlots:_vm._u([{key:"item.sockets",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sockets.length)+" ")]}},{key:"item.isPrivate",fn:function(ref){
var value = ref.value;
return [_c('RoomType',{attrs:{"is-private":value}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isMultiLeaveSupported && !item.isPrivate)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"disabled":_vm.isReadonly,"small":""},on:{"click":function($event){return _vm.clear(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tag-off-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("rooms.clear")))])]):_vm._e(),(_vm.isMultiDisconnectSupported)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"disabled":_vm.isReadonly,"small":""},on:{"click":function($event){return _vm.disconnect(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("rooms.disconnect")))])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }