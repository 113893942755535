var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbItems}}),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.servers,"footer-props":_vm.footerProps},scopedSlots:_vm._u([{key:"item.uptime",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDuration(value))+" ")]}},{key:"item.lastPing",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.delaySinceLastPing(value))+" ")]}},{key:"item.healthy",fn:function(ref){
var value = ref.value;
return [_c('ServerStatus',{attrs:{"healthy":value}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.healthy)?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.removeServer(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }