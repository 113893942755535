var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t("rooms.title")))]),_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.roomsAsObjects,"dense":""},on:{"click:row":_vm.displayDetails},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isSocketLeaveSupported)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"disabled":_vm.isReadonly,"small":""},on:{"click":function($event){return _vm.leave(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tag-off-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("sockets.leave")))])]):_vm._e()]}}])}),_c('v-card-text',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-combobox',{staticClass:"select-room d-inline-block mr-3",attrs:{"search-input":_vm.newRoom,"label":_vm.$t('sockets.join-a-room'),"items":_vm.availableRooms,"item-value":"name","item-text":"name","disabled":_vm.isReadonly,"return-object":false},on:{"update:searchInput":function($event){_vm.newRoom=$event},"update:search-input":function($event){_vm.newRoom=$event}}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"type":"submit","small":"","disabled":_vm.isReadonly}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tag-plus-outline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("sockets.join")))])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }